import React from 'react'
import Slider from '@mui/material/Slider'
import BaseQuestion from '../baseQuestion'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'

const MatrixQuadSlider = ({ displayComments, disabled, saveResponse, fullResponse, reponse, question, comment, commentUser, showComments, questionHelpText }) => {
  const [options, setOptions] = React.useState([])
  const { t } = useTranslation()
  const [marks, setMarks] = React.useState([])
  const [step, setStep] = React.useState(0)
  const [responseState, setResponseState] = React.useState({
    physical_impact: { value: 0, choiceIndex: 0, option: null, nextOption: null, pourcentage: 0 },
    financial_impact: { value: 0, choiceIndex: 0, option: null, nextOption: null, pourcentage: 0 },
    risk: { value: 0, choiceIndex: 0, option: null, nextOption: null, pourcentage: 0 },
    probability: { value: 0, choiceIndex: 0, option: null, nextOption: null, pourcentage: 0 }
  })
  const [sliderColor, setSliderColor] = React.useState({ physical_impact: '', financial_impact: '', probability: '', risk: '' })

  React.useEffect(() => {
    if (question?.option) {
      setOptions(question.option)
    }
  }, [question])

  React.useEffect(() => {
    if (options.length > 0) {
      const long = options.length
      setStep(100 / (long * 20))
      const list = options.map((op, index) => {
        if (op) {
          const step = (100 - 10) / (long - 1)
          const mark = {
            value: 10 + (index * step),
            label: index + 1,
            text: op.text ? op.text : ''
          }
          return mark
        }
      })
      setMarks(list)
    }
  }, [options])

  React.useEffect(() => {
    console.log('res', reponse)
    if (reponse) {
      console.log('res', reponse)
      setResponseState({
        physical_impact: reponse.physical_impact || responseState.physical_impact,
        financial_impact: reponse.financial_impact || responseState.financial_impact,
        probability: reponse.probability || responseState.probability,
        risk: reponse.risk || responseState.risk
      })
    }
  }, [reponse])

  const debouncedSaveResponse = React.useCallback(
    debounce((state) => {
      saveResponse(state)
    }, 300),
    [saveResponse]
  )
  const handleSliderChange = (e, type) => {
    const value = e.target.value
    const step = (100 - 10) / (options.length - 1)
    const ch = (value - 10 + step) / step
    const Index = Number(ch.toFixed(4).slice(0, -2))
    const choiceIndex = Math.round(ch * 10) / 10
    const pourcentage = Math.floor((Index - Math.floor(Index)) * 100)
    const clearIndex = Math.floor(Index)
    const option = options[clearIndex - 1]?.text || null
    const nOption = options[clearIndex]?.text || null

    setResponseState((prevState) => {
      const updatedState = {
        ...prevState,
        [type]: { value, choiceIndex, option, nextOption: nOption, pourcentage, question: question.uuid }
      }
      debouncedSaveResponse({ // Save the entire response state
        complexValue: updatedState,
        question: question.uuid,
        state: 'draft'
      })
      return updatedState
    })
  }

  const handleSave = () => {
    const response = {
      complexValue: responseState,
      question: question.uuid,
      state: 'draft'
    }
    debouncedSaveResponse(response)
  }

  React.useEffect(() => {
    const keysToCheck = ['probability', 'risk', 'physical_impact', 'financial_impact']
    const updatedColors = keysToCheck.reduce((acc, key) => {
      acc[key] = responseState[key]?.choiceIndex >= 1 ? '#3096d1' : '#de6868'
      return acc
    }, {})

    setSliderColor(prev => ({ ...prev, ...updatedColors }))
  }, [responseState])

  return (
    <BaseQuestion
      displayComments={displayComments}
      disabled={disabled}
      sendData={handleSave}
      questionHelpText={questionHelpText}
      reponse={fullResponse}
      showComments={showComments}
      commentUser={commentUser}
      comment={comment}
      question={question}
    >
      <h5 className="text-lg pb-2 px-2">{question.text}</h5>
      <div className='w-full pl-4 flex gap-20'>
        <div className='w-2/3'>
          <div>
            <h5 className="text-lg"> {t('consultant.materiality_matrix.labels.physical_impact')}</h5>

            <Slider
              disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
              aria-label="Custom marks"
              value={responseState.physical_impact?.value || 0}
              onChange={(e) => handleSliderChange(e, 'physical_impact')}
              valueLabelFormat={responseState.physical_impact?.choiceIndex || 0}
              step={step}
              marks={marks}
              sx={{ color: sliderColor.physical_impact, '& .MuiSlider-rail': { color: sliderColor.physical_impact } }}
              valueLabelDisplay="auto"
            />

          </div>
          <div>
            <h5 className="text-lg"> {t('consultant.materiality_matrix.labels.financial_impact')}</h5>
            <Slider
              disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
              aria-label="Custom marks"
              value={responseState.financial_impact?.value || 0}
              onChange={(e) => handleSliderChange(e, 'financial_impact')}
              valueLabelFormat={responseState.physical_impact?.choiceIndex || 0}
              step={step}
              marks={marks}
              sx={{ color: sliderColor.financial_impact, '& .MuiSlider-rail': { color: sliderColor.financial_impact } }}
              valueLabelDisplay="auto"
            />
          </div>

          <div>
            <h5 className="text-lg">{t('consultant.materiality_matrix.labels.urgence')}</h5>
            <Slider
              disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
              aria-label="Custom marks"
              value={responseState.risk?.value || 0}
              onChange={(e) => handleSliderChange(e, 'risk')}
              valueLabelFormat={responseState.risk?.choiceIndex || 0}
              step={step}
              marks={marks}
              sx={{ color: sliderColor.risk, '& .MuiSlider-rail': { color: sliderColor.risk } }}
              valueLabelDisplay="auto"
            />
          </div>
          <div>
            <h5 className="text-lg">{t('consultant.materiality_matrix.labels.probability')}</h5>
            <Slider
              disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
              aria-label="Custom marks"
              value={responseState.probability?.value || 0}
              onChange={(e) => handleSliderChange(e, 'probability')}
              onChangeCommitted={handleSave}
              valueLabelFormat={responseState.probability?.choiceIndex || 0}
              step={step}
              marks={marks}
              sx={{ color: sliderColor.probability, '& .MuiSlider-rail': { color: sliderColor.probability } }}
              valueLabelDisplay="auto"
            />
          </div>
          {
          // add material_quantity input here i need to ask yann about it and how they want it
          }
        </div>
        <div className='flex flex-col justify-center'>
          {marks
            ? marks.map((op, index) => (
              op?.label !== 0 && (
                <div className='flex gap-2' key={index}>
                  <span className='px-1 bg-gray-300 rounded-sm max-h-fit'>{op?.label || index + 1}</span>
                  <p className='text-gray-800 text-md'>{op.text || undefined}</p>
                </div>
              )
            ))
            : undefined
          }
          <div />
        </div>
      </div>
    </BaseQuestion>
  )
}

MatrixQuadSlider.propTypes = {
  text: PropTypes.string,
  displayComments: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  responses: PropTypes.array,
  commentUser: PropTypes.string,
  saveResponse: PropTypes.func,
  reponse: PropTypes.object,
  question: PropTypes.object.isRequired,
  comment: PropTypes.object,
  questionHelpText: PropTypes.string,
  showComments: PropTypes.bool,
  fullResponse: PropTypes.object
}

export default MatrixQuadSlider
