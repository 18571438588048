import React, { useState, useEffect, useRef } from 'react';
import {
  MaterialityMatrix,
  DefaultColorPolicy,
  ColorPolicy,
  MaterialityMatrixRef,
  TopFilterSortPolicy,
  DefaultTopFilterSortPolicy,
  CircleSizePolicy,
  DefaultCircleSizePolicy,
  ImpactSizePolicy,
  AxisSelectionPolicy,
  DefaultAxisSelectionPolicy,
  FilterPolicy,
  DefaultFilterPolicy,
  ValueFilterPolicy,
  CategoryFilterPolicy,
  DefaultCategoryFilterPolicy,
  FilterImpactPolicy,
  FilterImpactAveragePolicy
} from '@valueandco/vcdesign/packages/materiality-matrix';

import { Slider, SliderChangeEvent } from "primereact/slider";
import { Spinner } from '../../components/loadData/spinner';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from "primereact/checkbox";
import { InputSwitch } from "primereact/inputswitch";
import { SelectButton } from "primereact/selectbutton";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import './materialityMatrixStyles.css';
import { Collapse, IconButton, Box } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';




// Declare categoryColorMap outside to persist across calls
const categoryColorMap: { [key: string]: string } = {
  Environnemental: "#28A212",
  Sociétal: "#CABF13",
  Gouvernance: "#E28F16",
  Transverses: "#0F8CCA"
};


const urgenceColorPanel: { [key: string]: string } = {
  critical: '#d62424',
  max: '#eba91c',
  medium: '#d9e92d',
  low: '#2b8f22'
}

const urgenceColorPanelText: { [key: string]: string } = {
  "15-20": "#d62424",
  "10-15": '#eba91c',
  "5-10": '#d9e92d',
  "0-5": '#2b8f22'
}

// Define CategoryColorPolicy
const CategoryColorPolicy: ColorPolicy = {
  getColor: (point) => {
    const category = point.categoryHierarchy.split('/')[0];
    const parentCategory = categoryColorMap[category];
    return parentCategory || '#FFFFFF'; // Default to white if no color assigned
  }
};

const urgenceColorPolicy: ColorPolicy = {
  getColor: (point) => {
    const totalImpact = (point.internalStakeholder.urgence + point.externalStakeholder.urgence) / 2;
    if (totalImpact > 15) return urgenceColorPanel.critical;
    if (totalImpact > 10) return urgenceColorPanel.max;
    if (totalImpact > 5) return urgenceColorPanel.medium;
    if (totalImpact > 0) return urgenceColorPanel.low;
    return urgenceColorPanel.default;
  }
};



function MaterialityMatrixChart({ data, idEntity }) {
  const [isLoading, setIsLoading] = useState(true);
  const [matrixData, setMatrixData] = useState([]);
  const matrixRef = useRef<MaterialityMatrixRef>(null);
  const [currentPolicy, setCurrentPolicy] = useState<'uniform' | 'category' | 'urgence'>('category');
  const [currentSizePolicy, setCurrentSizePolicy] = useState<'uniform' | 'impact'>('impact');
  const [displayTopFirst, setDisplayTopFirst] = useState(0);
  const [filteredPointOpacity, setFilteredPointOpacity] = useState(0.2);
  const [filterKey, setFilterKey] = useState<string>('internalStakeholder.alignment');
  const [minThreshold, setMinThreshold] = useState<number>(0);
  const [maxThreshold, setMaxThreshold] = useState<number>(1);
  const [minImpactThreshold, setMinImpactThreshold] = useState<number>(0);
  const [maxImpactThreshold, setMaxImpactThreshold] = useState<number>(20);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [xAxis, setXAxis] = useState('internalStakeholder.physical_impact');
  const [yAxis, setYAxis] = useState('externalStakeholder.physical_impact');
  const navigate = useNavigate()

  const { t } = useTranslation()


  const help_text = {
    "axis_help_text": "consultant.materiality_matrix.help_text.axis_help_text",

    "filter_help_text": "consultant.materiality_matrix.help_text.filter_help_text",
    "filter_category_help_text": "consultant.materiality_matrix.help_text.filter_category_help_text",
    "filter_alignement_help_text": "consultant.materiality_matrix.help_text.filter_alignement_help_text",
    "filter_impact_help_text": "consultant.materiality_matrix.help_text.filter_impact_help_text",
    "filter_impact_phy_help_text": "consultant.materiality_matrix.help_text.filter_impact_phy_help_text",

    "show_hidden_points": "consultant.materiality_matrix.help_text.show_hidden_points",

    "points_help_text": "consultant.materiality_matrix.help_text.points_help_text",

    "color_uniform_help_text": "consultant.materiality_matrix.help_text.color_uniform_help_text",
    "color_category_help_text": "consultant.materiality_matrix.help_text.color_category_help_text",
    "color_urgence_help_text": "consultant.materiality_matrix.help_text.color_urgence_help_text",

    "size_impact_help_text": "consultant.materiality_matrix.help_text.size_impact_help_text",
    "size_uniform_help_text": "consultant.materiality_matrix.help_text.size_uniform_help_text"
  }


  const colorOptions = [
    { label: t('consultant.materiality_matrix.color_size_options.uniform'), value: "uniform" },
    { label: t('consultant.materiality_matrix.color_size_options.categories'), value: "category" },
    { label: t('consultant.materiality_matrix.color_size_options.urgence'), value: "urgence" },
  ];

  const sizeOptions = [
    { label: t('consultant.materiality_matrix.color_size_options.uniform'), value: "uniform" },
    { label: t('consultant.materiality_matrix.color_size_options.impact'), value: "impact" }
  ];


  useEffect(() => {
    if (data) {
      setIsLoading(false);
      setMatrixData(data);
      const initialCategories: string[] = Array.from(new Set(data.map(point => point.categoryHierarchy.split('/')[0])));
      setSelectedCategories(initialCategories);
      if (data.length >= 50) { setDisplayTopFirst(50); }
      else { setDisplayTopFirst(data.length / 2); }

    }
  }, [data]);

  useEffect(() => {
    if (!matrixRef.current) return;
    const newCategoryFilterPolicy: CategoryFilterPolicy = new DefaultCategoryFilterPolicy();
    matrixRef.current.setCategoryFilterPolicy(newCategoryFilterPolicy, selectedCategories);

    matrixRef.current.updateDisplayTopFirst(displayTopFirst);
  }, [matrixRef.current, selectedCategories, displayTopFirst]);

  // Extract keys from data points for axis selection
  const stakeholderFields = {
    internalStakeholder: [
      'urgence',
      'physical_impact',
      'financial_impact',
      'probability'
    ],
    externalStakeholder: [
      'urgence',
      'physical_impact',
      'financial_impact',
      'probability'
    ]
  };
  const stakeholderFilterFields = {
    internalStakeholder: [
      'probability',
      'alignment'
    ],
    externalStakeholder: [
      'probability',
      'alignment'
    ]
  };
  type AxisOption = {
    label: string;
    value: string;
    field: string;
  };

  const generateAxisOptions = (stakeholderFields: Record<string, string[]>): AxisOption[] => {
    const stakeholderLabels: Record<string, string> = {
      internalStakeholder: "Interne",
      externalStakeholder: "Externe"
    };

    return Object.entries(stakeholderFields).flatMap(([stakeholder, fields]) =>
      fields.map(field => ({
        label: t(`consultant.materiality_matrix.axis.${stakeholder}.${field}`),
        value: `${stakeholder}.${field}`,
        field: field, 
      }))
    );
  };

  // Usage
  const axisOptions = generateAxisOptions(stakeholderFields);
  const filterRange = generateAxisOptions(stakeholderFilterFields);



  const handleSizePolicyChange = (policyType: 'uniform' | 'impact') => {
    if (!matrixRef.current) return;

    setCurrentSizePolicy(policyType);
    switch (policyType) {
      case 'uniform':
        matrixRef.current.setCircleSizePolicy(new DefaultCircleSizePolicy());
        break;
      case 'impact':
        matrixRef.current.setCircleSizePolicy(new ImpactSizePolicy());
        break;
    }
  };

  const handlePolicyChange = (policyType: 'uniform' | 'category' | 'urgence') => {
    if (!matrixRef.current) return;

    setCurrentPolicy(policyType);
    switch (policyType) {
      case 'uniform':
        matrixRef.current.setColorPolicy(new DefaultColorPolicy());
        break;
      case 'category':
        matrixRef.current.setColorPolicy(CategoryColorPolicy);
        break;

      case 'urgence':
        matrixRef.current.setColorPolicy(urgenceColorPolicy);
        break;
    }
  };

  let xAxisRef = useRef<string>('internalStakeholder.urgence');
  let yAxisRef = useRef<string>('externalStakeholder.urgence');

  const handleAxisChange = (axis: 'x' | 'y', value: string) => {
    if (!matrixRef.current) return;

    // Update only the axis that changed
    if (axis === 'x') {
      if (yAxis === value) {
        alert(t('consultant.materiality_matrix.alerts.same_field'));
        return;
      }
      xAxisRef.current = value;
      setXAxis(value);
    } else if (axis === 'y') {
      if (xAxis === value) {
        alert(t('consultant.materiality_matrix.alerts.same_field'));
        return;
      }
      yAxisRef.current = value;
      setYAxis(value);
    }

    // Create a new axis policy based on the current axis selections
    const newAxisPolicy: AxisSelectionPolicy = {
      getAxisKeys: () => ({
        xKey: axis === 'x' ? value : xAxisRef.current,  // Use the changed value for xKey
        yKey: axis === 'y' ? value : yAxisRef.current,  // Use the changed value for yKey
      }),
    };

    // Apply the new axis selection policy
    matrixRef.current.setAxisSelectionPolicy(newAxisPolicy);
  };

  const handleFilterChange = (key: string, minThreshold: number, maxThreshold: number) => {
    if (!matrixRef.current) return;

    setFilterKey(key);
    setMinThreshold(minThreshold);
    setMaxThreshold(maxThreshold);

    const newFilterPolicy: FilterPolicy = new ValueFilterPolicy();
    matrixRef.current.setFilterPolicy(newFilterPolicy, key, minThreshold, maxThreshold);
  };

  const handleImpactFilterChange = (minThreshold: number, maxThreshold: number) => {
    if (!matrixRef.current) return;

    setMinImpactThreshold(minThreshold);
    setMaxImpactThreshold(maxThreshold);

    const newFilterImpactPolicy: FilterImpactPolicy = new FilterImpactAveragePolicy();
    matrixRef.current.setFilterImpactPolicy(newFilterImpactPolicy, minThreshold, maxThreshold);
  };

  const handleCategoryFilterChange = (selectedCategories: string[]) => {
    if (!matrixRef.current) return;

    setSelectedCategories(selectedCategories);

    const newCategoryFilterPolicy: CategoryFilterPolicy = new DefaultCategoryFilterPolicy();
    matrixRef.current.setCategoryFilterPolicy(newCategoryFilterPolicy, selectedCategories);
  };

  const categoryOptions = Array.from(new Set(matrixData.map(point => point.categoryHierarchy.split('/')[0])));


  const [threshold, setThreshold] = useState<number[]>([0, 1]);
  const [impactThreshold, setInmpactThreshold] = useState<number[]>([0, 20]);

  const handleSliderChange = (e: SliderChangeEvent) => {
    setThreshold(e.value);
    handleFilterChange(filterKey, e.value[0], e.value[1]);
  };

  const handleImpactSliderChange = (e: SliderChangeEvent) => {
    setInmpactThreshold(e.value);
    handleImpactFilterChange(e.value[0], e.value[1]);
  };

  const handleSelectAllChange = (e: { checked: boolean }) => {
    if (e.checked) {
      setSelectedCategories(categoryOptions);
      matrixRef.current?.setCategoryFilterPolicy(new DefaultCategoryFilterPolicy(), categoryOptions);
    } else {
      setSelectedCategories([]);
      matrixRef.current?.setCategoryFilterPolicy(new DefaultCategoryFilterPolicy(), []);
    }
  };

  const ColorLegend = () => {
    if (currentPolicy == 'category') {
      return (
        <div className="color-legend max-w-fit p-2 gap-2 flex">
          {Object.entries(categoryColorMap).map(([category, color]) => (
            <div key={category} className="flex items-center mb-2">
              <div className="rounded-full" style={{ backgroundColor: color, width: "1rem", height: "1rem", minWidth: "1rem", minHeight: "1rem", }}></div>
              <span className="text-sm font-medium text-gray-800 ml-2">{category}</span>
            </div>
          ))}
        </div>
      );
    }
    if (currentPolicy == 'urgence') {
      return (
        <div className="color-legend max-w-fit p-2 gap-2 flex">
          {Object.entries(urgenceColorPanelText).map(([impact, color]) => (
            <div key={impact} className="flex items-center mb-2">
              <div className="rounded-full" style={{ backgroundColor: color, width: "1rem", height: "1rem", minWidth: "1rem", minHeight: "1rem", }}></div>
              <span className="text-sm font-medium text-gray-800 ml-2">{t(impact)}</span>
            </div>
          ))}
        </div>
      )
    }
  };

  const [hoveredColorPolicy, setHoveredColorPolicy] = useState(null);
  const [hoveredSizePolicy, setHoveredSizePolicy] = useState(null);

  // Custom template to handle hover events for Color SelectButton
  const colorItemTemplate = (option) => (
    <div
      className="cursor-pointer font-semibold color-target-component"
      onMouseEnter={() => setHoveredColorPolicy(option)}
      onMouseLeave={() => setHoveredColorPolicy(null)}>
      <Tooltip
        target=".color-target-component"
        className="custom-tooltip"
        content={t(help_text[`color_${hoveredColorPolicy?.value || currentPolicy}_help_text`])}
        position="top"
      />
      {option.label} {/* Ensure your options have a 'label' field */}
    </div>
  );

  // Custom template to handle hover events for Size SelectButton
  const sizeItemTemplate = (option) => (
    <div
      className="cursor-pointer font-semibold size-target-component w-full h-full relative"
      onMouseEnter={() => setHoveredSizePolicy(option)}
      onMouseLeave={() => setHoveredSizePolicy(null)}
    >
      {/* This div will take the same size as the parent */}
      <div className="w-full h-full flex items-center justify-center">
        {option.label}
      </div>

      {/* Tooltip will be positioned absolutely relative to the parent */}
      <Tooltip
        target=".size-target-component"
        className="custom-tooltip"
        content={t(help_text[`size_${hoveredSizePolicy?.value || currentSizePolicy}_help_text`])}
        position="top"
      />
    </div>
  );

  const [open, setOpen] = useState(true);






  return (
    <div className='flex gap-4 mt-4'>
      <div className="w-full">
        <div className="flex justify-between w-full pb-5">
          <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('consultant.materiality_matrix.materiality_matrix')} </h5>
        </div>
        {isLoading ? (
          <div className='mx-auto'><Spinner /></div>
        ) : (
          <MaterialityMatrix
            key={JSON.stringify(matrixData)}
            ref={matrixRef}
            data={matrixData}

            x_axis={t(`consultant.materiality_matrix.axis.${xAxis}`)}
            y_axis={t(`consultant.materiality_matrix.axis.${yAxis}`)}
            width={1000}
            height={650}
            colorPolicy={CategoryColorPolicy}
            circleSizePolicy={new ImpactSizePolicy()}
            topFilterSortPolicy={new DefaultTopFilterSortPolicy()}
            axisSelectionPolicy={new DefaultAxisSelectionPolicy()}
            filterImpactPolicy={new FilterImpactAveragePolicy()}
            filterPolicy={new ValueFilterPolicy()}
            categoryFilterPolicy={new DefaultCategoryFilterPolicy()}
            onFilterChange={(filterState) => {
              console.log('Filter state updated:', filterState);
            }}
          />
        )}
        <div className='w-full flex justify-center' >
          <ColorLegend />
        </div>
      </div>
      <div>
        <Box display="flex" flexDirection="column" alignItems="center">
          <IconButton onClick={() => setOpen(!open)}>
            {open? <KeyboardDoubleArrowRightIcon/> : <KeyboardDoubleArrowLeftIcon/>}
          </IconButton>
          <Collapse
            in={open} orientation="horizontal"
            sx={{ transformOrigin: "right", minWidth: "150px" }}>
            <Box p={2} bgcolor="white" width={400}>
              <div className="flex flex-col w-full bg-white px-4 py-2 ">
                <h2 className="text-2xl text-black mb-2">{t('consultant.materiality_matrix.labels.matrix_parameters')}</h2>
                <div className="flex flex-col gap-1">

                  <div className='flex'>
                    <label className="block text-xl text-black text-gray-800 mr-1" >{t('consultant.materiality_matrix.labels.axes')}
                    </label>
                    <Tooltip target=".axes-target-icon" className='custom-tooltip' content={t(help_text.axis_help_text)} position="top" />
                    <InfoOutlinedIcon className="text-gray-600 cursor-pointer axes-target-icon" />
                  </div>
                  <div>
                    <label className="block text-lg font-medium text-gray-800">{t('consultant.materiality_matrix.labels.axe_abscisses')}</label>
                    <Dropdown
                      value={xAxis || NaN}
                      onChange={(e) => handleAxisChange('x', e.target.value)}
                      options={axisOptions}
                      optionLabel="label"
                      placeholder=""
                      className="custom-dropdown w-full"
                      panelStyle={{ maxHeight: '450px', overflowY: 'auto' }}
                    />
                  </div>
                  <div>
                    <label className="block text-lg font-medium text-gray-800">{t('consultant.materiality_matrix.labels.axe_ordonnees')}</label>
                    <Dropdown
                      value={yAxis || NaN}
                      onChange={(e) => handleAxisChange('y', e.target.value)}
                      options={axisOptions}
                      optionLabel="label"
                      placeholder=""
                      className="custom-dropdown w-full"
                    />
                  </div>
                </div>

                {/* filters */}
                {/* Category Filter Input */}
                <div className="flex flex-col mt-4">
                  <div className='flex'>
                    <label className="text-xl text-black text-gray-800 mr-1" >{t('consultant.materiality_matrix.labels.filters')}</label>
                    <Tooltip target=".filter-target-icon" className='custom-tooltip' content={t(help_text.filter_help_text)} position="top" />
                    <InfoOutlinedIcon className="text-gray-600 cursor-pointer filter-target-icon" />
                  </div>

                  <div className="flex flex-col py-1 pl-2 block rounded-md border-gray-300 focus:ring-blue-500 mt-1">
                    <div className="flex items-center mb-2">
                      <Checkbox
                        onChange={handleSelectAllChange}
                        checked={selectedCategories.length === categoryOptions.length}
                        style={{ borderColor: "black", fill: 'black', color: 'black' }}
                      />
                      <label htmlFor="selectAll" className="text-md font-semibold text-gray-750 ml-2 ">
                        {t('consultant.materiality_matrix.labels.categories')}
                      </label>
                    </div>
                    {categoryOptions.map((category) => (
                      <div key={category} className="flex items-center mb-2 pl-5">
                        <Checkbox
                          onChange={e => {
                            const selectedOptions = e.target.checked
                              ? [...selectedCategories, category]
                              : selectedCategories.filter((c) => c !== category);
                            handleCategoryFilterChange(selectedOptions);
                          }}
                          value={category}
                          checked={selectedCategories.includes(category)}
                          style={{ borderColor: "black", fill: 'black', color: 'black' }}
                        />
                        <label htmlFor={category} className="text-md font-medium text-gray-750 ml-2">
                          {category}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>


                {/* Alignement */}
                <div className="flex flex-col justify-start relative">
                  {/* Label */}
                  <div className='flex mb-1 '>
                    <label className="block text-lg font-medium text-gray-800 mr-1" >{t('consultant.materiality_matrix.labels.alignement')}
                    </label>
                    <Tooltip target=".alignement-target-icon" className="custom-tooltip align-baseline" content={t(help_text.filter_alignement_help_text)} position="top" />
                    <InfoOutlinedIcon className="text-gray-600 cursor-pointer alignement-target-icon" />
                  </div>

                  {/* Slider */}
                  <Slider
                    value={threshold}
                    onChange={handleSliderChange}
                    className="custom-slider mt-1"
                    range
                    min={0}
                    max={1}
                    step={0.1}
                    // Custom styles for the slider track (thicker slider)
                    trackStyle={{ height: '8px' }} // Adjust the height to make it thicker
                    handleStyle={{ height: '20px', width: '20px' }} // Optional: Adjust handle size
                  />

                  {/* Values displayed in percent and positioned in the top right */}
                  <div className="absolute top-0 right-0 flex space-x-4 text-md text-gray-900">
                    <span >{(threshold[0] * 100).toFixed(0)}% à {(threshold[1] * 100).toFixed(0)}%</span>
                  </div>
                </div>

                {/* Impact */}
                <div className="flex flex-col justify-start relative mt-3 mb-2">
                  {/* Label */}
                  <div className='flex'>
                    <label className="block text-lg font-medium text-gray-800 mr-1" >{t('consultant.materiality_matrix.labels.impact_filter')}
                    </label>
                    <Tooltip target=".impact-target-icon" className="custom-tooltip align-baseline" content={t(help_text.filter_impact_phy_help_text)} position="top" />
                    <InfoOutlinedIcon className="text-gray-600 cursor-pointer impact-target-icon" />
                  </div>

                  {/* Slider */}
                  <Slider
                    value={impactThreshold}
                    onChange={handleImpactSliderChange}
                    className="custom-slider mt-1"
                    range
                    min={0}
                    max={20}
                    step={0.5}
                    // Custom styles for the slider track (thicker slider)
                    trackStyle={{ height: '8px' }} // Adjust the height to make it thicker
                    handleStyle={{ height: '20px', width: '20px' }} // Optional: Adjust handle size
                  />

                  {/* Values displayed in percent and positioned in the top right */}
                  <div className="absolute top-0 right-0 flex space-x-4 text-md text-gray-900">
                    <span >{impactThreshold[0]} à {impactThreshold[1]}</span>
                  </div>
                </div>

                {/* top points */}


                <div className="flex flex-col justify-start relative mt-2"> {/* Add 'relative' here */}
                  <div className='flex'>
                    <label className="block text-lg font-medium text-gray-800 mr-1" >{t('consultant.materiality_matrix.labels.decreasing_internal_impact')}
                    </label>
                    <InfoOutlinedIcon className="text-gray-600 cursor-pointer impact-target-icon" />
                    <Tooltip target=".impact-target-icon" className='custom-tooltip' content={t(help_text.filter_impact_help_text)} position="top" />
                  </div>

                  {/* Input positioned in the top right */}
                  <div className="absolute top-0 right-0"> {/* Position the input here */}
                    <input
                      type="number"
                      value={displayTopFirst}
                      onChange={(e) => {
                        let val = Math.max(1, Number(e.target.value));
                        setDisplayTopFirst(val);
                        matrixRef.current?.updateDisplayTopFirst(val);
                      }}
                      min={0}
                      max={matrixData ? matrixData.length : 100}
                      className="w-16 border border-gray-300 rounded p-1 text-sm no-arrows" /* Add 'no-arrows' class */
                    />
                  </div>

                  {/* Slider and other content */}
                  <div className="flex items-center gap-4 mt-3"> {/* Add margin-top to avoid overlap */}
                    <Slider
                      value={displayTopFirst}
                      onChange={(e) => {
                        const val = Math.max(1, e.value);
                        setDisplayTopFirst(val);
                        matrixRef.current?.updateDisplayTopFirst(val);
                      }}
                      min={0}
                      max={matrixData ? matrixData.length : 100}
                      className='custom-slider w-full'
                    />
                  </div>
                </div>

                {/* Filtered point opacity */}
                <div className="flex justify-between gap-2 mt-4">
                  <div className='flex'>
                    <label className="text-lg font-medium text-gray-800">
                      {t('consultant.materiality_matrix.labels.show_hidden_points')}
                    </label>
                    <InfoOutlinedIcon className="text-gray-600 cursor-pointer show_hidden_points" />
                    <Tooltip target=".show_hidden_points" className='custom-tooltip' content={t(help_text.show_hidden_points)} position="top" />
                  </div>

                  {/* PrimeReact Toggle Switch */}
                  <InputSwitch
                    checked={filteredPointOpacity === 0.2}
                    onChange={(e) => {
                      const newValue = e.value ? 0.2 : 0;
                      setFilteredPointOpacity(newValue);
                      matrixRef.current?.updateFilteredPointOpacity(newValue);
                    }}
                  />
                </div>


                {/* Points*/}
                {/* Points color */}
                <div className="flex flex-col justify-between mt-2 gap-1">
                  {/* Points Label */}
                  <div className="flex">
                    <label className="block text-xl font-medium text-gray-800 mr-1">
                      {t('consultant.materiality_matrix.labels.points')}
                    </label>
                    <Tooltip
                      target=".point-target-icon"
                      className="custom-tooltip"
                      content={t(help_text.points_help_text)}
                      position="top"
                    />
                    <InfoOutlinedIcon className="text-gray-600 cursor-pointer point-target-icon" />
                  </div>

                  {/* Points Color */}
                  <div className="flex flex-col gap-1">
                    <h3 className="block text-lg font-medium text-gray-900">
                      {t('consultant.materiality_matrix.labels.point_color')}
                    </h3>
                    <SelectButton
                      value={currentPolicy}
                      options={colorOptions}
                      className=" max-w-fit"
                      onChange={(e) => handlePolicyChange(e.value)}
                      itemTemplate={colorItemTemplate} // Attach custom hover handlers
                    />
                  </div>

                  {/* Points Size */}
                  <div className="flex flex-col gap-1">
                    <h3 className="block text-lg font-medium text-gray-900">
                      {t('consultant.materiality_matrix.labels.point_size')}
                    </h3>
                    <SelectButton
                      value={currentSizePolicy}
                      options={sizeOptions}
                      className="max-w-fit"
                      onChange={(e) => handleSizePolicyChange(e.value)}
                      itemTemplate={sizeItemTemplate} />
                  </div>
                </div>

              </div>
            </Box>
          </Collapse>
        </Box>
      </div>

    </div>

  )
}



export default MaterialityMatrixChart;

