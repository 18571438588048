import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetMaterialityMatrixQuery } from '../../../features/materialityMatrix/materialityMatrixSlice'
import { useTranslation } from 'react-i18next'
import MaterialityMatrixChart from '../../../components/charts/materialityMatrix.tsx'

function MaterialityMatrixEvaluation() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id_matrix } = useParams()
  const { idEntity } = useParams()
  const [matrixResults, setData] = React.useState()
  const [matrixResultsFiltered, setMatrixResultsFiltered] = React.useState()
  const [parentTexts, setParentTexts] = React.useState()
  const [showedNumber, setShowedNumber] = React.useState()
  const [category, setCategory] = React.useState('all')
  const [bubbleCount, setBubbleCount] = React.useState(0)


  console.log('matrixResults', parentTexts)

  React.useEffect(() => {
    if (matrixResults) {
      const parentTexts = [...new Set(matrixResults.map(item => item.categoryHierarchy.split('/')[1]))]
      setParentTexts(parentTexts)
    }
  }, [matrixResults])

  const filteredCategory = () => {
    const data = category === 'all' ? matrixResults : matrixResults?.filter(bubble => bubble.parent_text === category)
    setMatrixResultsFiltered(data)
    setBubbleCount(data?.length)
  }
  const filterNumber = () => {
    if (category === 'all') {
      const data = matrixResults
        .sort((a, b) => b.rank - a.rank)
        .slice(0, showedNumber)
      setMatrixResultsFiltered(data)
    } else {
      const data = matrixResults?.filter(item => item.parent_text === category)
        .sort((a, b) => b.rank - a.rank)
        .slice(0, showedNumber)
      setMatrixResultsFiltered(data)
    }
  }

  React.useEffect(() => {
    if (category) {
      filteredCategory()
    }
    if (showedNumber) {
      filterNumber()
    }
  }, [category, showedNumber])

  const { data: materialityMatrixList } = useGetMaterialityMatrixQuery(id_matrix)

  React.useEffect(() => {
    if (materialityMatrixList?.matrix) {
      setData(Object.values(materialityMatrixList?.matrix))
      setMatrixResultsFiltered(Object.values(materialityMatrixList?.matrix))
      setBubbleCount(Object.values(materialityMatrixList?.matrix)?.length)
    }
  }, [materialityMatrixList])

  return (
    <div className="w-full flex justify-center px-auto">
      <div className="w-5/6 md:mx-4 pb-4 mt-2">
          <MaterialityMatrixChart data={matrixResultsFiltered || []} idEntity= {idEntity || undefined} />
      </div>
    </div>
  )
}

export default MaterialityMatrixEvaluation
