import React, { useState } from 'react'
import { useGetCampaignsQuery } from '../../../features/campaign/campaignsSlice'
import { Spinner } from '../../../components/loadData/spinner'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CreateMaterialityMatrix from './components/createMaterialityMatrix'
import SelectCampaign from './components/selectCampaign'

function CreateMaterialityMatrixEvaluation () {
  const { t } = useTranslation()
  const { idEntity } = useParams()
  const { data: campaigns, isSuccess, isLoading, isError, Error } = useGetCampaignsQuery(idEntity)
  const [materialityMatrix, setMaterialityMatrix] = useState({ name: '', intern_campaign: '', extern_campaign: '', entity: idEntity, matrix_shown_element_number: 100 })
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0') // Months are 0-based in JavaScript
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')

  const dateTimeString = `${year}${month}${day}-${hours}${minutes}-materiality_matrix`
  if (!materialityMatrix.name)
    materialityMatrix.name = dateTimeString

  if (isLoading) {
    return (
      <div className='flex justify-center'>
        <Spinner text="hell" />
      </div>)
  }
  if (isSuccess) {
    return (
      <div className='w-full flex justify-center'>
        <div className="md:w-2/3 lg:w-2/3 sm:w-2/3 bg-wite md:mx-8 pb-8 mt-8">
          <h5 className="text-2xl font-medium text-[#444444] w-max-fit">{t('consultant.materiality_matrix.create.create_materiality_matrix')}</h5>
          <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-2" >
            <div className='w-full flex justify-between'>
              <div className="md:w-2/3 w-4/5 md:mb-0 mt-1 relative rounded-sm ">
                <label htmlFor="price" className="block text-lg font-medium text-gray-800">
                  {t('consultant.materiality_matrix.create.evaluation_materiality_matrix')}
                </label>
                <input type="text" name="price" id="price" className="focus:ring-teal-500
        focus:border-teal-500 block  w-full pl-2 pr-12 text-lg  text-[#444444]
        rounded-sm" style={{ border: '1px solid #e8e6e6' }}
                  onChange={(e) => setMaterialityMatrix({ ...materialityMatrix, name: e.target.value })}
                  value={materialityMatrix?.name ? materialityMatrix.name : ''} />
              </div>
            </div>

            <SelectCampaign onChange ={(val) => setMaterialityMatrix({ ...materialityMatrix, intern_campaign: val })} text={t('consultant.materiality_matrix.create.intern_campaign')} campaigns={campaigns}/>
            <SelectCampaign onChange ={(val) => setMaterialityMatrix({ ...materialityMatrix, extern_campaign: val })} text={t('consultant.materiality_matrix.create.extern_campaign')} campaigns={campaigns}/>

            <CreateMaterialityMatrix materialityMatrix = {materialityMatrix}>
              <button className="bg-teal-600 float-right w-max-fit px-2 py-0.5 rounded-md mb-1 text-gray-100 text-md font-medium" style={{ cursor: 'pointer' }} >
                {t('consultant.buttons.create')}
              </button>
            </CreateMaterialityMatrix>
          </div>
        </div>
      </div>
    )
  }
  if (isError) {
    return <div> {Error} </div>
  }
}

export default CreateMaterialityMatrixEvaluation
