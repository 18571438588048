import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import BaseQuestion from './baseQuestion'
import PropTypes from 'prop-types'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export function MultiSelect (props) {
  const [personName, setPersonName] = React.useState([])
  const handleChange = (event) => {
    const {
      target: { value }
    } = event
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  return (
    <BaseQuestion questionHelpText={props.questionHelpText} fullResponse={props.fullResponse} showComments={props.showComments} commentUser ={props.commentUser} comment={props.comment} question={props.question}>

    <h5 className="text-xl">{props.question.text}</h5>
      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}>
          {props.question.option.map((option) => (
            <MenuItem key={option.id} value={option.text}>
              <Checkbox checked={personName.indexOf(option.text) > -1} />
              <ListItemText primary={option.text} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </BaseQuestion>
  )
}
MultiSelect.propTypes = {
  disabled: PropTypes.bool.isRequired,
  responses: PropTypes.array,
  commentUser: PropTypes.string,
  saveResponse: PropTypes.func,
  reponse: PropTypes.string,
  question: PropTypes.string,
  comment: PropTypes.object,
  questionHelpText: PropTypes.string,
  showComments: PropTypes.bool,
  fullResponse: PropTypes.object

}
export default MultiSelect
