import React from 'react'
import DocumentList from '../../../components/list/documentList'
import { useGetDocumentsByTypeQuery } from '../../../features/documents/documentSlice'
import { useTranslation } from 'react-i18next'

function DocumentsList () {
  const { t } = useTranslation()
  const { data: reports } = useGetDocumentsByTypeQuery('report')
  const { data: evaluationExcelList } = useGetDocumentsByTypeQuery('export_evaluation')
  const { data: materialityMatrixList } = useGetDocumentsByTypeQuery('export_materiality_matrix')

  return (
    <div className="w-full flex justify-center px-auto">
      <div className="md:w-2/3 lg:w-2/3 sm:w-2/3 md:mx-8 pb-8 mt-4">
        <div className="mt-1 shadow-sm rounded-md w-full mx-2 bg-white px-4 py-4 flex flex-col gap-4" >
          <>
            <div className="flex  w-full">
              <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('consultant.document.title.report')} </h5>
            </div>
            <DocumentList data={reports || []} />
          </>
          <>
            <div className="flex  w-full">
              <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('consultant.document.title.evaluation_excel')} </h5>
            </div>
            <DocumentList data={evaluationExcelList || []} />
          </>
          <>
            <div className="flex  w-full">
              <h5 className="text-2xl font-medium  text-[#444444] w-max-fit">{t('consultant.document.title.materiality_matrix_excel')} </h5>
            </div>
            <DocumentList data={materialityMatrixList || []} />
          </>
        </div>
      </div>
    </div>
  )
}

export default DocumentsList
